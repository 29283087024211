import React, {useContext} from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import {GlobalStateContext} from '../context/GlobalContextProvider'
import LayoutMaster from "../components/layout"
import '../styles/global.css'
import section from '../styles/sections.module.css'
import  '../styles/form.css'
import flex from '../styles/flexbox.module.css';
import {Section} from '../components/sections'

function Email({data, location}){
    const siteTitle = data.site.siteMetadata.title
    const state = useContext(GlobalStateContext)
    const {email} = state.language
    return(
        <LayoutMaster location={location} title={siteTitle}>
          <SEO title="Sucess" />
            <Section className={section.light} flex={`${flex.container} ${flex.justifyCenter} ${flex.alignCenter} min-full-height-contact`}>
              <section className={`form-container ${flex.container} ${flex.justifyCenter} ${flex.alignCenter}`}>
                  <div className={` ${flex.container} ${flex.directionColumn} ${flex.alignCenter}`}>
                    <h3>{email.title}</h3>
                    <p>{email.text}</p>
                  </div>
              </section>
            </Section>
        </LayoutMaster>
    )
}

export default Email

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`